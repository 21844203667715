<template>
  <div class="columns upper-menu is-vcentered">
    <div class="column flight-menu is-clickable" :class="[(currentStep === 'flight' ? 'menu-item-selected' : 'menu-item')]" @click="currentStep = 'flight'">
      flights
    </div>
    <div class="column package-menu is-clickable" :class="[(currentStep === 'package' ? 'menu-item-selected' : 'menu-item')]" @click="currentStep = 'package'">
      flight + hotel
    </div>
    <div class="column last-minute-menu is-clickable" :class="[(currentStep === 'lastMinute' ? 'menu-item-selected' : 'menu-item')]" @click="currentStep = 'lastMinute'">
      last minute <span class="last-minute-menu-more">and more</span>
    </div>
  </div>
  <div v-if="currentStep === 'flight'">
    <flightform></flightform>
  </div>
  <div v-if="currentStep === 'package'">
    <hotelform></hotelform>
  </div>
  <div v-if="currentStep === 'lastMinute'">
    <lastminute></lastminute>
  </div>
</template>

<script>
import { ref } from 'vue'
import '@vuepic/vue-datepicker/dist/main.css'
import FlightForm from '@/components/FlightForm.vue'
import HotelForm from '@/components/HotelForm.vue'
import LastMinute from '@/components/LastMinute.vue'

export default {
  components: { FlightForm, HotelForm, LastMinute },
  setup() {
    let currentStep = ref('flight')
  
    return {
      currentStep
    }
  }
}

</script>
<style lang="sass">
#app2
  .input-slot-image
    margin: 10px

  .menu-item
    background: #aaaeb7
    color: white
    text-transform: uppercase

  .menu-item-selected
    background: #cd0f21
    color: white
    text-transform: uppercase

  .columns
    max-width: 1160px !important
    width: 100%
    gap: 5px
    background: #F3f3f3

  input 
    height: 40px
    border: 1px solid #ddd
    border-radius: 4px
    width: 100%
    padding: 5px 10px

  .dp__input_icon_pad
    height: 40px

  input:hover
    border: 1px solid #aaaeb7

  .bm-input-wrap 
    position: relative

  input:focus 
    border: 1px solid #aaaeb7
    outline: none

  .label 
    font-size: 12px
    margin-bottom: 2px

  ul
    position: absolute
    bottom: 0
    left: 0
    transform: translateY(100%)
    width: 100%
    z-index: 1

  .results-dropdown
    position: absolute
    bottom: 0
    left: 0
    transform: translateY(100%)
    width: 100%
    background-color: 

  .result-item
    padding: 10px
    line-height: 1.2
  
  .result-item:nth-child(even)
    background-color: rgb(238, 238, 238)

  .result-item:nth-child(odd)
    background-color: rgb(243, 243, 243)

  .result-headline
    background-color: #DDD;

  ul li 
    padding: 10px
    line-height: 1.2

  ul li p 
    font-size: 12px

  li:nth-child(even)
    background-color: rgb(238, 238, 238)

  li:nth-child(odd)
    background-color: rgb(243, 243, 243)

  li:hover 
    background: #d7d7d7

  .results p span 
    margin-right: 5px

  .country-row 
    font-weight: 700
    margin-bottom: 3px

  .iata-row
    font-style: italic
    font-size: 10px

  .bm-search-button a
    background: #cd0f21
    height: 40px
    padding: 0 35px
    font-weight: 700
    color: white
    border-radius: 4px
    border: 1px solid #cd0f21
    display: flex
    justify-content: center
    align-items: center

  .dp__pointer
    height: 51px!important

  .bm-search-button a:hover
      color: #cd0f21
      background: white
      border: 1px solid #cd0f21

  .bm-search-button a:focus
      color: #cd0f21
      background: white
      border: 1px solid #cd0f21

  .dp__pointer
    height: 51px !important

  .last-minute-menu-more
    font-size: 50%

@media only screen and (max-width: 768px)
  #app2
    .columns
      margin: 0

    .column
      padding: 0

    .bm-search-button
      display: flex
      justify-content: center

    .bm-search-button a
      max-width: 250px

    .label 
      margin-top: 15px

</style>